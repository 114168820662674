import axios from 'axios';
const urlFacebook = "https://www.facebook.com/api/graphql/";
const urlInstagram = "";
const urlYoutube = "";

const state = {
  facebook: []
};

const mutations = {
  SET_FACEBOOK: (state, payload) => {
    state.facebook = payload;
  },
};

const actions = {
  getFacebook: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(urlFacebook,
        {
          variables: { "userID": "100058613656530" },
          doc_id: 5662591407179933
        }, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    })
    return promise;
  }
}


export default { state, mutations, actions };