<template>
  <section>
    <div class="container-fluid px-0">
      <div class="row mx-0">
        <div class="col-12 col-lg-9 px-0">
          <transition name="fade-transform" mode="out-in">
            <router-view :key="$route.name"></router-view>
          </transition>
        </div>
        <div class="d-none d-lg-block col-3 px-0 bg-light position-fixed" style="right:0;">
          <tab-contact-news />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import tabContactNews from './views/tab-contato-news/index.vue'
  export default {
    components: {
      tabContactNews,
    },
  }
</script>

<style scoped>
  /* section {
    height: 100vh;
  } */

  .container-fluid {
    overflow: hidden;
  }
</style>