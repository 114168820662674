<template>
  <section class="page-subsection bg-white" id="section-about">
    <start-page>
      <template v-slot:start>
        <h3>A empresa</h3>
        <p class="lead">|Introdução |Missão|</p>
        <p class="lead text-justify m-0">A MBD oferece soluções de automação comercial ao varejo desde a
          sua fundação, em 1996, sempre inovando em tecnologia para auxiliar a gestão das inúmeras
          empresas que utilizam nossos sistemas com os equipamentos e serviços que somos
          especialistas.</p>
        <blockquote class="quote py-0 py-lg-3">
          <b-icon icon="chat-quote" />
          <p>
            <span>Promover soluções com tecnologia de ponta para satisfação de nossos clientes.</span>
          </p>
        </blockquote>
      </template>
    </start-page>
    <div class="container px-0">
      <div class="row mx-0 align-items-center">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-icon icon="diagram3-fill" variant="info" font-scale="2.5" class="my-3" />
            <h5>Estrutura</h5>
            <p>|Laboratório |Atendimento |Produção |Frota |Infra|</p>
            <p class="text-justify m-0">Temos disponíveis ferramentas modernas e recursos próprios para
              atender com eficiência e confiança nossos clientes, buscando sempre a qualidade e o
              melhor resultado nas tarefas executadas.</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/empresa/about-01.jpg" draggable="false">
        </div>
      </div>
      <div class="row mx-0 align-items-center flex-row-reverse">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-iconstack font-scale="2.5" class="my-3">
              <b-icon stacked icon="person" shift-h="-6" shift-v="1" variant="info" scale="0.8"></b-icon>
              <b-icon stacked icon="person-fill" shift-h="0" variant="info"></b-icon>
              <b-icon stacked icon="person" shift-h="6" shift-v="1" variant="info" scale="0.8"></b-icon>
            </b-iconstack>
            <h5>Equipe</h5>
            <p>|Administração |Suporte |Técnica |DEV|</p>
            <p class="text-justify m-0">Nossos profissionais usam sempre tecnologias recentes que trazem os
              melhores benefícios na execução de suas tarefas com o objetivo de atender as
              expectativas dos nossos clientes.</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/empresa/about-02.jpg" draggable="false">
        </div>
      </div>
      <div class="row mx-0 align-items-center">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-iconstack font-scale="2.5" class="my-3">
              <b-icon stacked icon="hdd-rack-fill" variant="info" />
              <b-icon stacked icon="hdd-rack-fill" shift-v="7" variant="info" />
            </b-iconstack>
            <h5>Soluções</h5>
            <p>|Sistemas |Equipamentos |Serviços|</p>
            <p class="text-justify m-0">Todos os nossos projetos tem como foco oferecer uma solução completa
              de automação comercial para obter ao máximo o desempenho na execução dos processos
              operações de atendimento, produção e gestão.</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/empresa/about-03.jpg" draggable="false">
        </div>
      </div>
      <div class="row mx-0 align-items-center flex-row-reverse">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-iconstack font-scale="2.5" class="my-3">
              <b-icon stacked icon="tv" variant="info" />
              <b-icon stacked icon="phone-fill" shift-v="-4" shift-h="2" scale="0.5" variant="info" />
            </b-iconstack>
            <h5>Parceiros</h5>
            <p>|Compromisso |Compartilhamento |Propósito|</p>
            <p class="text-justify m-0">Os parceiros que representamos estão entre os maiores fabricantes e
              prestadores de serviço da área de TI, por isso entregamos produtos e serviços que geram
              um aproveitamento de 100% nas operações dos sistemas e equipamentos de nossas soluções.
            </p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/empresa/about-04.jpg" draggable="false">
        </div>
      </div>
    </div>
    <div class="container px-4 px-lg-0 pt-5">
      <div class="row mx-0">
        <div class="col-md-10 col-lg-8 text-center mx-auto">
          <h4>Por que somos a melhor escolha?</h4>
          <p><span class="big">Resultados</span></p>
          <p class="p text-justify m-0">Trabalhar com a MBD é benéfico para o seu negócio. Com mais de 20
            anos de experiência em desenvolvimento de sistemas e atendimento técnico em TI, nossos
            colaboradores podem fornecer uma solução ideal aos seus problemas de automação
            comercial. Nossos números de {{new Date().getFullYear()}}:</p>
        </div>
      </div>
      <div id="results" class="row p-5 mx-auto">
        <div v-for="(r,i) in results" :key="'result-'+i" class="col col-md-6 col-lg-3 my-3 my-lg-0 text-center">
          <h2 class="text-primary">
            <strong>
              <animated-number :duration="1000" :value="r.many" :formatValue="format"></animated-number>
            </strong>
          </h2>
          <h5> {{ r.title }} </h5>
        </div>
      </div>
    </div>
    <div v-if="$route.name!='home'" class="row">
      <copyright />
    </div>
  </section>
</template>

<script>
  import startPage from '../components/start-page.vue'
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      startPage,
      copyright,
    },
    data() {
      return {
        results: [{
          many: 0,
          title: 'OSs executadas, com média de 3 dias.'
        }, {
          many: 0,
          title: 'Suportes solucionados, com até 20 minutos para conclusão.'
        }, {
          many: 0,
          title: 'Atendimentos Externos realizados, com visitas em até 24 horas.'
        }, {
          many: 0,
          title: 'Contratos de Licença do Sistema MBD, com uma retenção de 94%.'
        }, ]
      }
    },
    mounted() {
      ((screen.width > 992) ? this.$el : window).addEventListener('scroll', () => this.start())
    },
    methods: {
      format(value) {
        return `+${parseInt(value)}`;
      },
      start() {
        let visible;
        if (document.querySelector('#results'))
          visible = (document.querySelector('#results').offsetTop -
            (((screen.width > 992) ? this.$el.offsetHeight : window.innerHeight) / 2));
        if (visible <= ((screen.width > 992) ? this.$el.scrollTop : window.scrollY)) {
          this.results[0].many = 1300;
          this.results[1].many = 16000;
          this.results[2].many = 3000;
          this.results[3].many = 700;
        }
      },
    }
  }
</script>

<style scoped>
  .quote {
    font-size: 32px;
    position: relative;
    margin-top: 40px;
    color: #1b1b1b;
    letter-spacing: .02em;
    font-style: italic;
    border: none;
    line-height: 1.25;
    z-index: 2;
    padding: 0 50px;
  }

  .quote .b-icon {
    font-size: 80px;
    color: rgba(27, 27, 27, 0.15);
    position: absolute;
    font-style: normal;
    top: 15px;
    left: 0;
    z-index: -1;
    line-height: 1;
  }

  .box-type-2 {
    padding: 0 5vw;
    font-size: medium;
    text-align: center;
  }

  .box-type-2 h5 {
    font-weight: 600;
  }

  h5 {
    line-height: 1.33333;
    font-size: 24px;
  }

  .emp-img {
    height: 100% !important;
    display: inline-block;
    max-width: 100%;
  }

  .big {
    font-size: 64px;
    font-weight: 700;
    font-family: Montserrat;
    line-height: 1.1;
  }

  .text-primary {
    font-size: -webkit-xxx-large;
    font-size: -moz-xxx-large;
    font-size: -ms-xxx-large;
    font-size: -o-xxx-large;
  }

  @media screen and (min-width: 1600px) {
    .box-type-2 {
      font-size: large;
    }
  }

  @media screen and (max-width: 992px) {
    .quote {
      font-size: 22px;
      padding-right: 0;
    }

    .big {
      font-size: 38px;
    }
  }
</style>