<template>
  <div class="mx-2 rounded" id="nav-news">
    <div v-if="news.length && (news!='failed')" id="news-itens">
      <template v-for="(n, i) in news">
        <a :href="n.guid" target="_black" :key="'news-'+i">
          <div class="news-bord mx-1 my-2 row aling-items-center">
            <div class="col-4 p-1 my-auto">
              <img class="w-100 " :src="n.avatar" draggable="false" />
            </div>
            <div class="col-8 px-1 conteudo position-relative">
              <b-badge v-if="i+1<=countNews" pill class="mx-auto p-0 text-danger position-absolute">
                Novo</b-badge>
              <div class="row m-0 py-2 aling-items-center">
                <h5 class="m-0 px-0">
                  <strong>{{n.post_title.slice(0, 35)}}... - {{n.post_date}}</strong>
                </h5>
                <div class="px-0">
                  <span>{{n.post_content.slice(0, 95)}} ...<strong>Saiba Mais</strong></span>
                </div>
              </div>
            </div>
          </div>
        </a>
      </template>
    </div>
    <div v-else-if="!news.length" class='p-3 mt-5'>
      <h3 class="text-center">Carregando
        <b-icon v-for="i in 3" :key="'load-'+i" icon="circle-fill" animation="throb" font-scale="0.5"
          class="text-success mx-1">
        </b-icon>
      </h3>
    </div>
    <div v-else class='p-3 text-center'>
      <b-icon icon="shield-exclamation" animation="throb" font-scale="5" class="text-danger my-3"></b-icon>
      <h5 style="font-size:x-large"><strong>Erro ao carregar</strong></h5>
      <p class="text-break">Error: Network Error</p>
    </div>
    <div class="px-5 my-3">
      <a href="http://mbd.com.br/news" target="_black">
        <h5 class="text-center text-break">Para mais notícias acesse Portal de Notícias
          <span class="link">www.mbd.com.br/news</span>
        </h5>
      </a>
    </div>
    <div class="row mx-0">
      <div class="col-auto px-0 mx-auto mb-3">
        <router-link to="/noticias/login"><small class="border rounded p-2">Entrar</small></router-link>
      </div>
    </div>
    <!-- $el.children[0].children[0].children[0].children[1].children[0].children[1].children[0].offsetWidth -->
  </div>
</template>

<script>
  export default {
    props: ['countNews'],
    computed: {
      news() {
        return this.$store.state.main.news;
      }
    },
    mounted() {
      setTimeout(() => {
        Array.from(document.querySelectorAll('#nav-news img')).map(i => {
          if (i.naturalHeight == 0 && i.naturalWidth == 0) i.src=require('@/assets/SemImagem.jpg')
        });
      }, 1);
    },
  }
</script>

<style scoped>
  #nav-news {
    height: calc(100vh - 60px);
    width: auto;
    overflow-y: auto;
    opacity: 1;
    border: 1px solid #e67e22;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .text-danger {
    font-size: xx-small;
    animation-name: Throb;
    animation-duration: 0.8s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }

  img {
    max-width: 125px;
    max-height: 125px;
    border-radius: 20px;
  }

  a {
    text-decoration: none;
  }

  h5,
  small {
    text-decoration: none;
    color: #007bff;
    font-size: small;
    font-weight: 600;
  }

  .conteudo {
    color: #333;
    font-size: small;
    overflow: hidden;
    /* word-break: break-all; */
  }

  .news-bord {
    background-color: white;
    border-radius: 15px;
  }

  .news-bord:hover {
    background-color: rgba(127, 140, 141, 0.5);
  }

  .information {
    white-space: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .link {
    color: #333;
    text-decoration: underline;
  }

  .link:hover {
    color: #00caff;
  }
</style>