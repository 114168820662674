const routes = [{
  path: '*',
  redirect: '/',
  name: 'all',
}, {
  path: '/',
  component: () => import('./master.vue'),
  children: [{
    path: '/',
    component: () =>
      import('./views/tab-home/index.vue'),
    name: 'home',
  }, {
    path: '/empresa',
    component: () =>
      import('./views/tab-empresa/tab-empresa.vue'),
    name: 'empresa',
  }, {
    path: '/sistemas',
    component: () =>
      import('./views/tab-sistemas/tab-sistemas.vue'),
    name: 'sistemas',
  }, {
    path: '/planos',
    component: () =>
      import('./views/tab-planos/tab-planos.vue'),
    name: 'planos',
    props: true,
  }, {
    path: '/suporte',
    component: () =>
      import('./views/tab-suporte/tab-suporte.vue'),
    name: 'suporte',
  }, {
    path: '/produtos',
    component: () =>
      import('./views/tab-produtos/tab-produtos.vue'),
    name: 'produtos',
  }, {
    path: '/combos',
    component: () =>
      import('./views/tab-combos/tab-combos.vue'),
    name: 'combos',
  }, {
    path: '/sistemas-info',
    component: () =>
      import('./views/tab-sistemas/components/system.vue'),
    name: 'sistemas-info',
    props: true,
  }]
}, {
  path: '/noticias/login',
  name: 'news-login',
  component: () => import('./views/news/Public/Login.vue'),
}, {
  path: '/noticias',
  component: () => import('./views/news/Index.vue'),
  children: [{
    path: '/noticias/inicio',
    name: 'news-inicio',
    component: () => import('./views/news/Cards.vue')
  }, {
    path: '/noticias/usuarios',
    name: 'news-todos-usuarios',
    component: () => import('./views/news/tab-users/Users.vue')
  }, {
    path: '/noticias/usuario/criar',
    name: 'news-criar-usuario',
    component: () => import('./views/news/tab-users/CreateUser.vue')
  }, {
    path: '/noticias/usuario/editar',
    name: 'news-editar-usuario',
    component: () => import('./views/news/tab-users/CreateUser.vue')
  }, {
    path: '/noticias/posts',
    name: 'news-todas-noticias',
    component: () => import('./views/news/tab-newspaper/Newspapers.vue')
  }, {
    path: '/noticias/posts/criar',
    name: 'news-criar-noticia',
    component: () => import('./views/news/tab-newspaper/CreateNewspaper.vue')
  }, {
    path: '/noticias/posts/editar',
    name: 'news-editar-noticia',
    component: () => import('./views/news/tab-newspaper/CreateNewspaper.vue')
  }],
}, {
  path: '/news-home',
  name: 'news-public',
  component: () => import('./views/news/Public/Index.vue'),
  children: [{
    path: '/news/',
    name: 'news-post',
    component: () => import('./views/news/Public/components/Noticias.vue')
  }, {
    path: '/news/inicio',
    name: 'news-lastest',
    component: () => import('./views/news/Public/components/UltimasNoticias.vue')
  }]
}];

export default routes