import axios from 'axios';

const state = {
  login: {},
  users: [],
  edit_user: {},
  news_list: {},
  filter_post: {},
  edit_post: {},
};

const mutations = {
  GET_AUTH: (state) => {
    if (localStorage.getItem('auth')) {
      let auth = JSON.parse(Buffer.from(localStorage.getItem("auth"), 'base64').toString('utf-8'))
      for (let item in auth) [state[item] = auth[item]]
      axios.defaults.headers.common['authorization'] = (state.login.token) ? state.login.token : null;
    }
  },
  SET_LOGIN: (state, payload) => {
    state.login = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
  SET_USERS: (state, payload) => {
    state.users = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
  EDIT_USERS: (state, payload) => {
    state.edit_user = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
  SET_NEWS_LIST: (state, payload) => {
    state.news_list = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
  SET_FILTER_POST: (state, payload) => {
    state.filter_post = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
  SET_EDIT_POST: (state, payload) => {
    state.edit_post = payload;
    localStorage.setItem("auth", Buffer.from(JSON.stringify(state), 'utf-8').toString('base64'));
  },
};

const actions = {
  logIn: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`login`, { secret: payload })
        .then((response) => {
          context.commit('SET_LOGIN', response.data);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  logout: (context, payload) => {
    context.commit('SET_LOGIN', payload);
    localStorage.removeItem('auth')
  },
  getUsers: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`usuarios?${payload || ''}`)
        .then((response) => {
          context.commit('SET_USERS', response.data);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  createUser: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`usuario`, payload)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  updateUser: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put(`usuario/${payload.id}`, payload)
        .then((response) => {
          context.commit('EDIT_USERS', {});
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  setStatus: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put(`status/${payload}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  getNewsList: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`noticias?${payload}`)
        .then((response) => {
          context.commit('SET_NEWS_LIST', response.data);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  setCreatePost: (context, payload) => {
    payload.post_status = (payload.post_status) ? 'publish' : 'draft'
    let promise = new Promise((resolve, reject) => {
      axios.post(`noticia`, payload)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  getEditPost: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`noticia/${payload}`)
        .then((response) => {
          context.commit('SET_EDIT_POST', response.data);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  setStatusPost: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.put(`noticia/status/${payload.post}`)
        .then((response) => {
          context.dispatch('getNewsList', payload.filter);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  setEditPost: (context, payload) => {
    payload.post_status = (payload.post_status) ? 'publish' : 'draft'
    let promise = new Promise((resolve, reject) => {
      axios.put(`noticia/${context.state.edit_post.id}`, payload)
        .then((response) => {
          context.commit('SET_EDIT_POST', {});
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  deletePost: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.delete(`noticia/${payload.post}`)
        .then((response) => {
          context.dispatch('getNewsList', payload.filter);
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
  getPost: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.get(`news/?p=${payload}`)
        .then((response) => {
          response.data.post_content = (response.data.post_content).replace(/<a /g, '<a target="_blank" ');
          resolve(response.data)
        })
        .catch((error) => reject(error.response));
    })
    return promise;
  },
}

export default {
  state,
  mutations,
  actions
};