<template>
  <section id="section-product" class="bg-white">
    <start-page :noImg="true" />
    <div class="container px-0">
      <div class="row mx-0">
        <b-carousel id="carousel-products" class="px-0" :interval='8000' fade indicators no-wrap :no-touch="false"
          v-model="slide">
          <b-carousel-slide v-for="(b,i) in banner" :key="'banner-'+i" :no-touch="false">
            <template #img>
              <img :src="require(`@/assets/produtos/${b}`)" class="d-block img-fluid w-100" :draggable="false">
            </template>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <div id="producs" class="row my-5 mx-2 align-items-center justify-content-center rounded bg-primary text-white">
        <div v-for="(p,i) in products" :key="'product-menu-'+i" @click="move(p.id)"
          :class="{'border-right':(($route.path!='/')?(i+1)<products.length:[1,3].includes(i+1))}"
          class="col-6 my-3 my-lg-0 col-lg h-100 text-center link">
          <p class="m-0"><strong>{{ p.title.toUpperCase() }}</strong></p>
          <p class="m-0"><b> {{ p.sistema }} </b></p>
          <p class="m-0 py-2">Saiba mais
            <b-icon icon="chevron-right"></b-icon>
          </p>
        </div>
      </div>
      <div v-for="(p,i) in products" :key="'product-'+i" :class="{'flex-row-reverse': i%2==1}"
        class="row mx-0 align-items-center">
        <div :id="p.id" class="col-12 col-md-8 px-0">
          <img :src="require(`@/assets/produtos/${p.banner}`)" class="w-100" alt="" draggable="false">
        </div>
        <div class="col-12 col-md-4 py-5 text-center">
          <h5><strong> {{ p.title.toUpperCase() }} </strong></h5>
          <p class="m-0" v-html="p.description"></p>
          <div v-if="p.link" class="my-3">
            <a v-if="p.link!='/combos'" :href="p.link" target="_blank">Saiba mais</a>
            <a v-else @click="plus(i)" href="#">Saiba mais</a>
          </div>
        </div>
        <div v-if="($route.path == '/') && (i==0)" class="row mx-0 px-0">
          <b-collapse id="collapse-combos" v-model="visible" class="px-0">
            <div class="col-11 px-0 text-right">
              <b-button @click="visible=false" id="btnFechar" size="sm" variant="danger" class="p-0 rounded">
                <b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon>
              </b-button>
            </div>
            <combos />
          </b-collapse>
        </div>
      </div>
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
    </div>
  </section>
</template>

<script>
  import startPage from '../components/start-page.vue'
  import combos from '../tab-combos/tab-combos.vue'
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      startPage,
      combos,
      copyright,
    },
    data() {
      return {
        banner: ['Produtos01.fw.png', 'Produtos02.fw.png', 'Produtos03.fw.png', 'Produtos04.fw.png',
          'Produtos05.fw.png'
        ],
        products: [{
            id: 'Combos',
            title: 'Combos',
            sistema: 'Equipamentos',
            banner: 'Combos.fw.png',
            description: 'Conte com equipamentos da MBD para atender as necessidades da gestão da sua empresa!',
            link: '/combos',
          },
          {
            id: 'ar-mbd',
            title: 'Ar MBD',
            sistema: 'Certificadora Digital',
            banner: 'AR.fw.png',
            description: 'A Ar MBD é portal ideal para você adquirir ou renovar o seu Certificado Digital.\nO Certificado Digital será a sua identidade no meio eletrônico!\nCom um Certificado Digital você reduz despesas, tem mais segurança e mobilidade, além de facilitar a entrega de declarações.',
            link: 'https://armbd.com.br/',
          },
          {
            id: 'docx-digital',
            title: 'DocX Digital',
            sistema: 'Assinador Digital',
            banner: 'docx.fw.png',
            description: 'O DocXDigital é a plataforma de assinatura eletrônica certa para a sua empresa!\nA plataforma certa para você assinar e armazenar os seus documentos, de qualquer lugar e dispositivo.',
            link: 'https://app.docxdigital.com.br/',
          },
          {
            id: 'lgpd',
            title: 'LGPD',
            sistema: 'Lei Geral de Proteção de Dados',
            banner: 'Produtos003.png',
            description: 'É muito importante que todo empreendedor esteja antenado na LGPD.\nA empresa que viola os direitos da LGPD pode sofrer sérias punições.',
            link: '',
          },
        ],
        slide: 0,
        sliding: null,
        visible: false,
      }
    },
    methods: {
      move(p) {
        const position = document.querySelector(`#${p}`).offsetTop;
        ((screen.width > 992) ? this.$el : window).scroll(0, position)
      },
      plus(i) {
        if (this.$route.path != '/') this.$router.push(this.products[i].link)
        else {
          this.visible = true;
          setTimeout(() => window.scroll(0, document.querySelector('#collapse-combos').offsetTop), 0);
        }
      }
    }
  }
</script>

<style scoped>
  #producs .border-right {
    border-right: 2px solid white;
  }

  .link {
    cursor: pointer;
  }

  #producs p {
    font-size: small;
  }

  #producs p strong {
    font-size: x-large;
  }
</style>

<style>
  #carousel-products ol li {
    width: 20px;
    height: 20px;
    margin: 0 0.5em;
    border: 1px solid #333;
    border-radius: 50%;
    background: #ffffff90;
  }

  #carousel-products ol li.active {
    background: #333;
    border: 1px solid #fff;
  }

  @media screen and (max-width: 992px) {
    #carousel-products ol {
      margin: 0;
    }

    #carousel-products ol li {
      width: 15px;
      height: 15px;
    }
  }
</style>