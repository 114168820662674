<template>
  <section class="page-subsection" id="section-store">
    <start-page :route="($route.params.collapse)?'/sistemas':'/'">
      <template v-slot:start>
        <h3>Planos</h3>
        <div class="d-flex justify-content-between align-items-center">
          <p class="lead my-0">Confira abaixo os nossos planos.</p>
          <button v-if="$route.params.simulation" @click="$router.go(-1)" id="bt-back" class="btn btn-primary p-1">
            <span>Nova simulação</span>
          </button>
        </div>
      </template>
    </start-page>
    <div class="container my-3">
      <div class="row">
        <div id="planos" class="col-12 col-lg-8 mx-auto mb-3">
          <div class="row justify-content-around justify-content-lg-between">
            <button class="btn p-0 mt-2 col-5 col-sm-4" @click="collapse()">
              <img src="@/assets/planos/Sistema Desktop.png" class="p-0 m-0 w-100" alt="Sistema Cloud Nuvem Web"
                draggable="false">
            </button>
            <a class="btn p-0 mt-2 col-5 col-sm-4" href="https://office.pdvx.com.br/nossos-planos/" target="_blank">
              <img src="@/assets/planos/Sistema Nuvem.png" class="p-0 m-0 w-100" alt="Sistema Desktop"
                draggable="false">
            </a>
          </div>
        </div>
        <b-collapse id="legado" v-model="visible" class="mt-2">
          <div class="container px-0 px-lg-5">
            <div class="row mx-0">
              <div class="range mt-5">
                <div class="col pt-2 px-0 text-right">
                  <b-button @click="collapse()" id="btnFechar" size="sm" variant="danger" class="p-0 rounded">
                    <b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon>
                  </b-button>
                </div>
                <div class="col-12 text-md-left">
                  <h3>Sistemas Desktop - Offline</h3>
                  <p>Sistema dependente de equipamento físico, rede estruturada e internet para
                    emissão de cupom fiscal </p>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <div v-for="(plano, i) in planos" :key="plano.name" class="col-10 col-md-4 col-lg-4 mx-auto py-3 px-md-2">
                <div class="box" :class="plano.cor">
                  <b-iconstack font-scale="5">
                    <b-icon stacked :icon="plano.icon"></b-icon>
                    <b-icon v-if="i>=0" stacked shift-h="-4" icon="square-fill" scale="0.2"></b-icon>
                    <b-icon v-if="i>=1" stacked shift-h="-1" icon="square-fill" scale="0.2"></b-icon>
                    <b-icon v-if="i>=2" stacked shift-h="2" icon="square-fill" scale="0.2"></b-icon>
                  </b-iconstack>
                  <h3 class="my-3 my-lg-5">{{ plano.name }}</h3>
                  <h3 class="mt-2 mt-lg-4 mb-0">{{ plano.value }}</h3>
                  <h3 class="mb-2 mb-lg-4 mt-0">{{ plano.type }}</h3>
                </div>
                <div v-b-modal.buyModal @click="form.plan=`Sistema Desktop - ${plano.name}`" class="box-about py-1">
                  <button class="text-light">
                    <h5 class="m-0">CompreAgora!</h5>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mx-0">
              <table id="table-planos-legado" class="table table-hover my-5">
                <thead>
                  <tr>
                    <th v-for="i in 4" :key="'planos-'+i" :class="{'p-laranja px-1':i==2,'p-azul':i==3,'p-verde px-1':i==4}"
                      scope="col" class="text-center py-2 rounded-top">
                      <strong> {{ (i==1)? 'Recursos' : (i==2)? 'BÁSICO I': (i==3)? 'BÁSICO II': 'COMPLETO' }} </strong>
                    </th>
                  </tr>
                </thead>
                <tbody id="body">
                  <template v-for="(rec, r) in table">
                    <tr v-if="rec.type=='row'" :key="r">
                      <th scope="row" class="tabletitle" colspan="4"><strong> {{ rec.name }} </strong></th>
                    </tr>
                    <tr v-if="rec.type=='item'" :key="r">
                      <th scope="row" class="recursos"> {{ rec.name }} </th>
                      <td v-for="i in 3" :key="'recurso-'+i" :class="{'p-laranja':i==1,'p-azul':i==2,'p-verde':i==3}">
                        <b-icon v-if="(rec.attr[i-1]===true)" icon="check" font-scale="1.5"></b-icon>
                        <b-icon v-else-if="(rec.attr[i-1]==false )" icon="dash"></b-icon>
                        <span v-else><strong> {{ rec.attr[i-1] }} </strong></span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </b-collapse>
      </div>
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
      <b-modal id="buyModal" centered scrollable size="lg" hide-header hide-footer>
        <div class="container px-0">
          <div class="row m-0">
            <div class="d-none d-lg-block col-5 px-0">
              <img src="@/assets/formulario.png" class="w-100 h-100" alt="" draggable="false">
            </div>
            <div class="col">
              <div class="row mx-0 align-items-center justify-content-center">
                <div class="col pt-2 px-0 text-right">
                  <b-icon @click="$bvModal.hide('buyModal')" icon="x-square" variant="danger" font-scale="2"></b-icon>
                </div>
                <div id="emailCompra" class="px-lg-5 mx-auto">
                  <div class="text-center"><span>Preencha o formulário, entraremos em contato</span></div>
                  <input :class="{'border-danger': errors.includes('name')}" class="form-control my-3"
                    id="form-buy-name" type="text" name="name" placeholder="Nome" v-model="form.name">
                  <input :class="{'border-danger': errors.includes('email')}" class="form-control my-3"
                    id="form-buy-email" type="email" name="email" placeholder="E-mail" v-model="form.email">
                  <input :class="{'border-danger': errors.includes('phone')}"
                    v-mask="['(##)# ####-####','(##) ####-####']" class="form-control my-3" id="form-buy-phone"
                    type="text" name="phone" placeholder="Telefone (xx) xxxx-xxxx" v-model="form.phone">
                  <input :class="{'border-danger': errors.includes('city')}" class="form-control my-3"
                    id="form-buy-cidade" type="text" name="cidade" placeholder="Cidade - UF" v-model="form.city">
                  <input :class="{'border-danger': errors.includes('segment')}" class="form-control my-3"
                    id="form-buy-segmento" type="text" name="segmento"
                    placeholder="Segmento (Açougue, Bazar, Padaria, etc)" v-model="form.segment">
                  <select :class="{'border-danger': errors.includes('plan')}" class="form-control my-3"
                    id="form-buy-plano" v-model="form.plan">
                    <option :value="null" selection>Plano...</option>
                    <!-- <option value="Sistema Cloud - BÁSICO I">Sistema Cloud - BÁSICO I</option> -->
                    <!-- <option value="Sistema Cloud - BÁSICO II">Sistema Cloud - BÁSICO II</option> -->
                    <!-- <option value="Sistema Cloud - COMPLETO">Sistema Cloud - COMPLETO</option> -->
                    <option value="Sistema Desktop - BÁSICO I">Sistema Desktop - BÁSICO I</option>
                    <option value="Sistema Desktop - BÁSICO II">Sistema Desktop - BÁSICO II</option>
                    <option value="Sistema Desktop - COMPLETO">Sistema Desktop - COMPLETO</option>
                  </select>
                  <textarea class="form-control my-3" id="form-buy-message" name="message"
                    placeholder="Mensagem (Opcional)" v-model="form.message"></textarea>
                  <div class="w-100 my-3 text-center">
                    <button @click="prepare()" :disabled="loading" id="btmBuy" type="button"
                      class="btn btn-success rounded">
                      <b-icon icon="cursor"></b-icon>
                      Enviar
                      <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </section>
</template>

<script>
  import startPage from '../components/start-page.vue'
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      startPage,
      copyright,
    },
    data() {
      return {
        planos: [{
          icon: 'battery',
          cor: 'p-laranja',
          name: 'BÁSICO I',
          value: 'R$ 30,00 Mês',
          type: 'Contrato Anual'
        }, {
          // icon: 'battery-half',
          icon: 'battery',
          cor: 'p-azul',
          name: 'BÁSICO II',
          value: '$ 48,00 Mês',
          type: 'Contrato Anual'
        }, {
          // icon: 'battery-full',
          icon: 'battery',
          cor: 'p-verde',
          name: 'COMPLETO',
          value: 'R$ 99,00 Mês',
          type: 'Contrato Mensal'
        }, ],
        table: [{
          name: 'PDV',
          type: 'row',
        }, {
          name: 'Venda',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'La Carte',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Delivery',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Comanda',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'SAT (Cupom Fiscal)',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Nota Fiscal Consumidor Eletrônica (NFC-e)',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Montagem de Pratos e Pizzas',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Impressões Remotas (Cozinha/Copa/etc)',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Sangria',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Mensalista',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Mobile (Celular e Tablet) e Atendimento Balcão/Salão',
          type: 'row',
        }, {
          name: 'Lançamentos de Pedidos',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Transferência de Mesa e Itens',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Montagem de Pratos e Pizzas',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Cancelamento',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Impressões Remotas (Cozinha/Copa/etc)',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Gestão (Retaguarda)',
          type: 'row',
        }, {
          name: 'Cadastros Diversos',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Cadastros Fiscais',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Estoque',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Financeiro',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Nota Fiscal Eletrônica (NF-e)',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Manifesto Eletrônico (MDF-e)',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Orçamentos',
          type: 'item',
          attr: [false, true, true]
        }, {
          name: 'Gerenciador de Permissões de Usuários',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Relatórios Diversos',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Licenças',
          type: 'row',
        }, {
          name: 'PDV (CAIXA)',
          type: 'item',
          attr: [1, 1, 2]
        }, {
          name: 'RETAGUARDA',
          type: 'item',
          attr: [1, 1, 0]
        }, {
          name: 'RETAGUARDA COM EMISSÃO (NF-e e MDF-e)',
          type: 'item',
          attr: [0, 0, 1]
        }, {
          name: 'MOBILE/TOUCH, MICROTERMINAL e PRÉ-VENDA',
          type: 'item',
          attr: [0, 2, 4]
        }, {
          name: 'Suporte',
          type: 'row',
        }, {
          name: 'Atendimentos horário comercial (Telefone e Remoto)',
          type: 'item',
          attr: [true, true, true]
        }, {
          name: 'Atendimentos (Telefone e Remoto) 24H',
          type: 'item',
          attr: [false, false, true]
        }, {
          name: 'Atendimento Presencial',
          type: 'item',
          attr: ['Consultar', 'Consultar', true]
        }, ],
        form: {
          name: '',
          email: '',
          phone: '',
          city: '',
          segment: '',
          plan: null,
          message: '',
        },
        errors: [],
        loading: false,
        visible: false,
      }
    },
    mounted() {
      this.$root.$on('bv::modal::hidden', (modalId) => {
        if (modalId == 'buyModal') this.errors = [];
      })
    },
    created() {
      if (this.$route.params.collapse) this.collapse();
    },
    methods: {
      collapse() {
        this.visible = !this.visible;
        if (this.visible) setTimeout(() =>
          ((screen.width > 992) ? this.$el : window).scroll(0, document.querySelector('#legado').offsetTop), 250);
      },
      prepare() {
        this.errors = [];
        if (!this.form.name || !this.form.email || !this.form.phone || !this.form.city || !this.form.plan ||
          !this.form.segment) {
          if (!this.form.name) this.errors.push('name')
          else this.errors.slice(this.errors.findIndex(e => e == 'name'), 1)
          if (!this.form.email) this.errors.push('email')
          else this.errors.slice(this.errors.findIndex(e => e == 'email'), 1)
          if (!this.form.phone) this.errors.push('phone')
          else this.errors.slice(this.errors.findIndex(e => e == 'phone'), 1)
          if (!this.form.city) this.errors.push('city')
          else this.errors.slice(this.errors.findIndex(e => e == 'city'), 1)
          if (!this.form.plan) this.errors.push('plan')
          else this.errors.slice(this.errors.findIndex(e => e == 'plan'), 1)
          if (!this.form.segment) this.errors.push('segment')
          else this.errors.slice(this.errors.findIndex(e => e == 'segment'), 1)
          this.$swal.fire({
            icon: 'error',
            title: 'Preenche os campos',
          })
          return
        }
        let mensagem =
          `Cidade: ${this.form.city}\n\nPlano: ${this.form.plan}\n\nSegmento: ${this.form.segment} \n\n${this.form.message}`;
        let send = {
          nome: this.form.name,
          email: this.form.email,
          telefone: this.form.phone,
          mensagem,
        }
        this.loading = true;
        this.$store.dispatch('contato', send).then(() => {
          this.$swal.fire({
            icon: 'success',
            title: 'Obrigado por este e-mail!!',
          })
          this.form = {
            name: '',
            email: '',
            phone: '',
            city: '',
            segment: '',
            plan: null,
            message: '',
          }
          this.$bvModal.hide('buyModal')
        }).catch((error) => this.$swal.fire({
          icon: 'error',
          title: `E-mail não enviado.\n${error}\nTente novamente mais tarde.`,
        })).finally(() => this.loading = false)
      }
    }
  }
</script>

<style scoped>
  #section-store {
    background: #F2F2F6;
  }

  .btn:not(.btn-primary) {
    border-radius: 1rem;
  }

  .box {
    padding: 5% 0 3% 0;
    border: 0 solid;
    border-radius: 5px 5px 0 0;
    color: white;
    text-align: center;
  }

  .box h3 {
    font-weight: bold;
    font-size: 36px;
  }

  .box h3.mt-0 {
    font-size: large;
  }

  .box-about {
    border-radius: 0 0 5px 5px;
    background-color: #666;
    text-align: center;
    cursor: pointer;
  }

  .box-about button {
    border: 0;
    background: transparent;
    color: white;
    animation-name: Throb;
    animation-duration: 0.8s;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
  }

  .p-verde {
    background-color: #2ecc71;
  }

  .p-azul {
    background-color: #3498db;
  }

  .p-laranja {
    background-color: #e67e22;
  }

  [class*='p-'] {
    color: white;
  }

  table thead th {
    border: 0;
  }

  table tr>* {
    padding: 0;
    font-weight: 100;
    text-align: left;
  }

  table td {
    text-align: center;
  }

  table strong {
    font-weight: 700;

  }

  .plan {
    border-radius: 5px 5px 0 0;
    width: 15%;
  }

  .tabletitle {
    text-align: left !important;
    background-color: lightgray;
  }

  .table thead th {
    min-width: 70px;
  }

  img {
    object-fit: cover;
    object-position: center;
  }

  @media screen and (max-width: 992px) {
    table tr {
      font-size: 80%;
    }
  }
</style>

<style>
  #buyModal .modal-body {
    padding: 0;
  }
</style>