<template>
  <section class="page-subsection" id="section-suporte">
    <start-page>
      <template v-slot:start>
        <h3>Suporte</h3>
      </template>
    </start-page>
    <div class="container px-0">
      <div class="row mx-0 align-items-center">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-iconstack font-scale="2.5" class="my-3">
              <b-icon stacked icon="calendar3" variant="info" />
              <b-icon stacked icon="circle-fill" variant="white" shift-h="5" shift-v="-5" scale="0.7"></b-icon>
              <b-icon stacked icon="clock" variant="info" shift-h="5" shift-v="-5" scale="0.7" />
            </b-iconstack>
            <h5>Horário Comercial</h5>
            <p><strong>Segunda à Sexta das 08:00 às 20:00</strong>.</p>
            <p><strong>Sábado das 09:00 às 13:00.</strong></p>
            <p>Exceto feriados.</p>
            <p class="my-2"><strong>Telefone:</strong> (12) 3152-9360 e (12) 9 8229-5777
              <img src="@/assets/suporte/whtz.png" draggable="false"/>
            </p>
            <p class="my-2">E-mail: suporte@mbd.com.br</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/suporte/suporte-01.jpg" draggable="false">
        </div>
      </div>
      <div class="row mx-0 align-items-center flex-row-reverse">
        <div class="col-12 col-lg-6">
          <div class="box-type-2 my-3 my-lg-0">
            <b-icon icon="telephone-inbound-fill" variant="info" scale="2.5" class="my-3"></b-icon>
            <h5 class="my-2">Plantão</h5>
            <p><strong>Sábado a partir das 13:00 às 20:00.</strong></p>
            <p class="my-2"><strong>Telefone:</strong> (12) 9 8229-5777</p>
            <p class="my-2">E-mail: suporte@mbd.com.br</p>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-0">
          <img class="emp-img" src="@/assets/suporte/suporte-02.webp" draggable="false">
        </div>
      </div>
      <div class="row my-5 mx-0 text-center">
        <h1 class="display-4">Acessos</h1>
        <div v-for="acesso in acessos" :key="acesso.title"
          class="col py-3 card bg-transparent border-0 align-items-center justify-content-between">
          <img :src="require(`@/assets/suporte/${acesso.img}`)" class="acesso" alt="" draggable="false">
          <h5 class="h-auto d-flex align-items-center"> {{ acesso.title }} </h5>
          <a class="btn btn-success" :href="acesso.link">Download</a>
        </div>
      </div>
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
    </div>
  </section>
</template>

<script>
  import startPage from '../components/start-page.vue'
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      startPage,
      copyright,
    },
    data() {
      return {
        acessos: [{
          title: 'Anydesk',
          img: 'anydesk.png',
          link: 'https://download.anydesk.com/AnyDesk.exe',
        }, {
          title: 'Anydesk Android',
          img: 'AnyMobile.png',
          link: 'https://download.anydesk.com/anydesk.apk?_ga=2.98524511.1356851270.1593524992-489912785.1583342796',
        }, {
          title: 'Team Viewer',
          img: 'teamviewer-2.png',
          link: 'https://download.teamviewer.com/download/TeamViewerQS.exe',
        }]
      }
    }
  }
</script>

<style scoped>
  #section-suporte {
    background: #f8f9fa;
  }

  .box-type-2 {
    padding: 0 2.5rem;
    font-size: medium;
    text-align: center;
  }

  h5 {
    line-height: 1.33333;
    font-size: 24px;
    font-weight: 600;
  }

  p {
    margin: 0;
    text-align: left;
  }

  .emp-img {
    height: 100% !important;
    display: inline-block;
    max-width: 100%;
  }

  .acesso {
    width: 100px;
    height: 100px;
  }

  @media screen and (min-width: 1600px) {
    .box-type-2 {
      font-size: large;
    }
  }

  @media screen and (max-width: 992px) {
    .box-type-2 {
      padding: 0 1.5rem;
    }
  }
</style>