<template>
  <section id="section-contact-news" class="bg-white">
    <!-- Nav tabs -->
    <ul class="nav nav-tabs mx-2 my-1 position-relative" role="tablist">
      <li v-for="(item,i) in list" :key="item" @click="status=((i)?'tabNews':'tabContato')" class="nav-item">
        <span :class="{'active': item==((status=='tabNews')?'Notícias':'Contatos')}" class="nav-link m-0">{{ item }}
          <b-badge v-if="i==1" :active="countNews>0" pill id="contNews">{{ countNews }}</b-badge>
        </span>
      </li>
    </ul>
    <component ref="tabs" :is="status" v-bind="{countNews}" />
  </section>
</template>

<script>
  import tabContato from './tab-contato.vue';
  import tabNews from './tab-news.vue';
  export default {
    components: {
      tabContato,
      tabNews,
    },
    data() {
      return {
        status: 'tabContato',
        list: ['Contatos', 'Notícias'],
        loading: false,
      }
    },
    beforeMount() {
      this.loading = true;
      this.$store.dispatch("getNews")
    },
    computed: {
      countNews() {
        let count = 0;
        if (Array.isArray(this.$store.state.main.news))
          this.$store.state.main.news.map(n => {
            if (n.post_date.slice(3, 8) == new Date().toISOString().slice(2, 7).split('-').reverse().join('/'))
              count++;
          })
        return count;
      }
    },
  }
</script>

<style scoped>
  #section-contact-news {
    /* background-image: linear-gradient(35deg, rgba(51, 51, 51, 0.7), transparent), url(../../assets/contato.jpg); */
    background: #f8f9fa;
    background-size: 500%;
    height: 100vh;
  }

  #section-contact-news image {
    opacity: 0.5;
  }

  #contNews {
    position: absolute;
    right: 20px;
    border-radius: 15px;
    background-color: #dc3545;
    font-size: xx-small;
    font-weight: normal;
    color: white;
    padding: 3px 5px;
    top: 5px;
  }

  #contNews:not(.active) {
    display: none;
  }

  ul.nav.nav-tabs {
    font-size: 15px;
    border: 0;
  }

  li.nav-item {
    width: 50%;
    border-radius: .25rem .25rem 0 0;
    cursor: pointer;
  }

  .nav-link {
    color: #333;
    text-align: center;
    border: 1px solid #333 !important;
    opacity: 0.8;
  }

  .nav-link.active {
    opacity: 1;
    font-weight: 700;
    color: #fff !important;
    border-color: transparent !important;
    background-color: #0d6efd !important;
  }

  .nav-link:not(.active):hover {
    background-color: white;
    color: #0d6efd;
    opacity: 0.8;
  }

  @media screen and (max-width: 992px) {
    #section-contact-news {
      height: auto;
    }
  }
</style>