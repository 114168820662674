<template>
  <section id="section-combos" class="bg-white">
    <div class="container px-3">
      <start-page :noImg="true" :route="'/produtos'" />
      <img :src="require(`@/assets/combos/Equipamentos.jpg`)" class="w-100" alt="" draggable="false">
      <div v-for="(p,i) in products" :key="'combo-'+i" :class="{'flex-row-reverse': i%2==1}" class="row mx-0 align-items-center">
        <div :id="p.id" class="col-12 col-md-6 px-0 text-center">
          <img :src="require(`@/assets/combos/${p.banner}`)" width="auto" height="150px" alt="" draggable="false">
        </div>
        <div class="col-12 col-md-6 p-3 border">
          <p class="largerText py-3 m-0"><strong> {{ p.title.toUpperCase() }} </strong></p>
          <p class="smallerText m-0" v-html="p.description"></p>
          <div v-if="p.link" class="text-center my-3">
            <a :href="p.link" target="_blank">Saiba mais</a>
          </div>
        </div>
      </div>
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
    </div>
  </section>
</template>

<script>
  import startPage from '../components/start-page.vue'
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      startPage,
      copyright,
    },
    data() {
      return {
        products: [{
          title: 'MONITOR TOUCH SCREEN 15.6″ TELA CAPACITIVA PROJETADA (PCAP) POSTECH',
          description: 'BAIXO CONSUMO DE ENERGIA TELA WIDE SCREEN DESIGN, TECNOLOGIA TOUCH SCREEN CAPACITIVA PROJETADA (PCAP), PERMITINDO APLICAÇÕES MULTI TOUCH, BAIXO CONSUMO DE ENERGIA.',
          link: 'https://postech.com.br/produtos/gpp156n12002x3/',
          banner: 'monitor-touch.jpg',
        }, {
          title: 'CELERON QUAD CORE N4120 2.4GHZ / 4GB/ SSD 128GB / WIFI E BLUETOOTH / AUTO POWER-ON',
          description: 'RÁPIDO E COMPACTO.\nA MENOR CPU DA LINHA COM 2.4GHz , 4MB DE CACHE, WI-FI E BLUETOOTH 4.2.',
          link: 'https://postech.com.br/produtos/celeron-quad-core-n4100-2-4ghz-4gb-ssd-120gb-hdmi-e-vga/',
          banner: 'celeron.jpg',
        }, {
          title: 'CPU INTEL CELERON DUAL CORE 2.41GHZ COM TELA TOUCH SCREEN DE 15.6″',
          description: "COMPACTO\nPDV Touch Screen Modular Capacitivo Multitoque, resistente a poeira, gordura e respingos d'água. MINICPU com 2 serias, 4G de RAM, SSD de 120GB,  Windows 10 IOT instalado de fábrica, baixo consumo de energia, não aquecem e pode trabalhar sem ventiladores para diminuição do índice de ruído",
          link: 'https://postech.com.br/produtos/pdv-modular/',
          banner: 'intel-celeron.jpg',
        }, {
          title: 'TABLET 10.1 QUAD CORE 1.3 GHZ / 2GB / 32GB COM SUPORTE',
          description: 'MINI PDV ANDROID (POS1075 +SUPORTE)\nPDV COMPACTO COM ANDROID COM UMA BASE ROBUSTA PARA USO EM CHECKOUT. ELE NÃO OCUPA ESPAÇO',
          link: 'https://postech.com.br/produtos/pos1075-suporte-mini-pdv-android/',
          banner: 'tablet.jpg',
        }, {
          title: 'ALL-IN-ONE INTEL CELERON J1900 QUAD-CORE 2.0GHZ COM TELA TOUCH SCREEN',
          description: 'PDV INTEGRADO ALL IN ONE COM TELA TOUCH CAPACITIVA PROJETADA\nCompatibilidade: Windows, Android, Linux',
          link: 'https://postech.com.br/produtos/intel-celeron-j1900-quad-core-2-0ghz-3/',
          banner: 'all-in-one.jpg',
        }, {
          title: 'PC POS All-In-One 10" com Impressora Integrada',
          description: 'FUSION é um PC POS de 10" com design compacto e elegante, ideal para o varejista como frente de caixa e para a gestão dos serviços voltados ao cliente (recargas de celular, apostas, pagamentos de boletos)',
          link: 'https://www.custom.biz/pt_PT/produto/hardware/pos-systems/pc-pos/fusion-237',
          banner: 'fusion-all-in-one.jpg',
        }, {
          title: 'M10 MiniPDV',
          description: 'Solução compacta para o frente de caixa.\nO Mini PDV M10 é um All in One Android compacto perfeito para o pequeno e médio varejo',
          link: 'https://www.elgin.com.br/Produtos/Automacao/PDV/M10',
          banner: 'm10.jpg',
        }, {
          title: 'IMPRESSORA TÉRMICA NÃO FISCAL PRNT ID TOUCH',
          description: 'Equipada com display touchscreen de 2.4”, permite exibir o QR CODE do PIX no display, facilitando o recebimento por PIX no PDV do nosso sistema com checagem automática na conta bancária. Ideal para emissão de Cupom Fiscal (SAT) e Nota Fiscal Eletrônica ao Consumidor (NFC-e)',
          link: 'https://www.controlid.com.br/automacao-comercial/printid-touch/',
          banner: 'printId.jpg',
        }, {
          title: 'SAT iD',
          description: 'Homologado pela Secretaria da Fazenda do Estado de São Paulo (SEFAZ/SP), o Sat Fiscal SAT iD é o Sistema Autenticador e Transmissor de Cupons Fiscais Eletrônicos da Control iD. Esse tipo de sistema é uma exigência da SEFAZ/SP para que o varejista possa emitir cupons fiscais eletrônicos.',
          link: 'https://www.controlid.com.br/automacao-comercial/satid/',
          banner: 'controlId.jpg',
        }, {
          title: 'SAT-CARE',
          description: 'O SAT-CARE é um emissor de cupons fiscais eletrônicos desenvolvido pela CS DEVICES para utilização no Estado de São Paulo e em outros Estados que adotam a mesma legislação da SEFAZ SP.',
          link: 'https://plataformacare.com.br/sat-care/',
          banner: 'care.jpg',
        }, {
          title: 'SMARTPOS STONE COM SISTEMA DE CAIXA (PDV)',
          description: 'POS STONE INTEGRADO AO CAIXA (PDV)',
          link: 'https://www.stone.com.br/maquina-de-cartao/',
          banner: 'stone.png'
        }]
      }
    }
  }
</script>

<style scoped>
  .xLargeText {
    font-size: x-large;
  }

  .largerText {
    font-size: larger;
  }

  .smallText {
    font-size: small;
  }

  .smallerText {
    font-size: smaller;
  }

  .xSmallText {
    font-size: x-small;
  }

  .xxSmallText {
    font-size: xx-small;
  }

  @media screen and (max-width: 1200px) {
    img{
      height: auto;
      max-height: 180px;
      width: auto;
      max-width: 100%;
      padding: 1.5rem;
    }
  }
</style>