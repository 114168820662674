import axios from 'axios';

const state = {
  news: [],
};
const mutations = {
  SET_NEWS: (state, payload) => {
    state.news = payload;
  },
};
const actions = {
  getNews: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      let post = [];
      axios.get(`?limit=${payload || ''}`).then((response) => {
        //VARRE TODOS OS DADOS
        response.data.forEach(value => {
          let { post_title, post_date, post_content, guid } = value
          let first = '', end = '', clear = '', avatar = '';
          //IMAGEM
          first = String(post_content).indexOf('<img src="') + 10;
          end = String(post_content).indexOf('"', first);
          avatar = String(post_content).slice(first, end);
          avatar = (post_content.search('<img src="') === -1) ? require('@/assets/SemImagem.jpg') : avatar;

          //REMOVER LINKS
          first = String(post_content).indexOf('<!-- wp:image');
          end = String(post_content).indexOf('<!-- /wp:image -->', first) + 18;
          clear = String(post_content).slice(first, end);
          post_content = String(post_content).replace(clear, "");

          post_content = String(post_content).replace(/<td>/g, "");
          post_content = String(post_content).replace(/<\/td>/g, "");

          first = String(post_content).indexOf('<!-- wp:table');
          end = String(post_content).indexOf('<!-- /wp:table -->', first) + 18;
          clear = String(post_content).slice(first, end);
          post_content = String(post_content).replace(clear, "");

          first = String(post_content).indexOf('<!-- wp:code');
          end = String(post_content).indexOf('<!-- /wp:code -->', first) + 17;
          clear = String(post_content).slice(first, end);
          post_content = String(post_content).replace(clear, "");

          first = String(post_content).indexOf('<code>');
          end = String(post_content).indexOf('</code>', first) + 7;
          clear = String(post_content).slice(first, end);
          post_content = String(post_content).replace(clear, "");

          post_content = String(post_content).replace(/&nbsp;/g, "");
          post_content = String(post_content).replace(/<p>/g, "");
          post_content = String(post_content).replace(/<p style="text-align:center">/g, "");
          post_content = String(post_content).replace(/<\/p>/g, "");
          post_content = String(post_content).replace(/<br>/g, "");

          post_content = post_content.replace(/<[^>]*>/g, '');

          //VERIFICA CONTEUDO MAIOR QUE O PERMITIDO
          post_content === "" ? post_content = "Sem descrição" : post_content;

          post.push({ guid, avatar, post_title, post_date: post_date.slice(0, 10), post_content })
        });
        context.commit('SET_NEWS', post);
        resolve(response)
      }).catch((error) => {
        context.commit('SET_NEWS', 'failed');
        console.log(error);
        reject(error)
      })
    })
    return promise
  },
  contato: (context, payload) => {
    let promise = new Promise((resolve, reject) => {
      axios.post(`contato/`, payload).then((response) => {
        resolve(response)
      }).catch((error) => reject(error));
    })
    return promise;
  }
};

export default { state, mutations, actions };