<template>
  <div id="nav-contact">
    <div class="form-contato rounded mx-2 py-2 bg-white text-left">
      <h3 class="text-center">Entre em contato</h3>
      <div class="px-3 full">
        <div class="form-group position-relative my-3">
          <b-icon icon="person" :class="{'text-danger': errors.includes('name')}"></b-icon>
          <input v-model="form.name" :class="{'border-danger': errors.includes('name')}" class="form-control"
            id="form-name" type="text" name="name" placeholder="Nome">
        </div>
        <div class="form-group position-relative my-3">
          <b-icon icon="at" :class="{'text-danger': errors.includes('email')}"></b-icon>
          <input v-model="form.email" :class="{'border-danger': errors.includes('email')}" class="form-control"
            id="form-email" type="email" name="email" placeholder="E-mail">
        </div>
        <div class="form-group position-relative my-3">
          <b-icon icon="telephone" :class="{'text-danger': errors.includes('phone')}"></b-icon>
          <input v-model="form.phone" :class="{'border-danger': errors.includes('phone')}"
            v-mask="['(##)# ####-####','(##) ####-####']" class="form-control" id="form-phone" type="text" name="phone"
            placeholder="Telefone - (xx) xxxx-xxxx" maxlength="16">
        </div>
        <div class="form-group position-relative my-3">
          <b-icon icon="chat-square-text" :class="{'text-danger': errors.includes('message')}"></b-icon>
          <textarea v-model="form.message" :class="{'border-danger': errors.includes('message')}" class="form-control"
            id="form-message" name="message" rows="4" placeholder="Digite sua mensagem de E-mail..."></textarea>
        </div>
        <div id="anexo" class="form-group position-relative my-3 text-left">
          <label for='input-file' class="d-flex align-items-center">
            <span id='file-name'
              :class="{'text-danger': form.file&&form.file.type!='application/pdf', 'text-success': form.file.type=='application/pdf'}">
              <b-icon v-if="form.file.type=='application/pdf'" icon="file-earmark-check-fill" class="mx-1 position">
              </b-icon>
              <b-icon v-else-if="form.file" icon="file-earmark-x-fill" class="mx-1 position"></b-icon>
              {{ (form.fileName.length) ? `${form.fileName.slice(0,20)}${(form.fileName.length>20)?'...':''}` : 'Anexo em PDF' }}
              <b-icon icon="paperclip" rotate="-30" font-scale="1.5" class="position"></b-icon>
            </span>
            <input @change="loadFile($event)" :class="{'border-danger': errors.includes('file')}" id='input-file'
              type='file' accept="application/pdf" />
          </label>
        </div>
        <div class="form-button text-center m-0">
          <button @click="prepare" :disabled="loading" class="btn btn-md btn-outline-orange px-5">
            <b-icon icon="cursor"></b-icon>Enviar
            <b-icon v-if="loading" icon="arrow-clockwise" animation="spin"></b-icon>
          </button>
        </div>
      </div>
      <ul class="my-2 px-0 d-flex justify-content-center">
        <li class="btn-lg mx-2">
          <a href="https://www.facebook.com/mbdsolucoes/" target="_blank">
            <b-icon icon="facebook" variant="light" class="icon"></b-icon>
          </a>
        </li>
        <li class="btn-lg mx-2">
          <a href="https://www.instagram.com/mbdsolucoes/" target="_blank">
            <b-icon icon="instagram" variant="light" class="icon"></b-icon>
          </a>
        </li>
        <li class="btn-lg mx-2">
          <a href="https://www.linkedin.com/company/mbd-inform-tica" target="_blank">
            <b-icon icon="linkedin" variant="light" class="icon"></b-icon>
          </a>
        </li>
        <li class="btn-lg mx-2">
          <a href=" https://www.youtube.com/channel/UCS6zp0md0pjZzjyljXr3TEA" target="_blank">
            <b-icon icon="youtube" variant="light" class="icon"></b-icon>
          </a>
        </li>
      </ul>
      <div class="d-block d-lg-none row mx-0">
        <copyright />
      </div>
    </div>
  </div>
</template>

<script>
  import copyright from '../components/copyright.vue'
  export default {
    components: {
      copyright,
    },
    data() {
      return {
        form: {
          name: '',
          email: '',
          phone: '',
          message: '',
          file: '',
          fileName: '',
          fileAnexo: '',
        },
        errors: [],
        loading: false,
      }
    },
    methods: {
      prepare() {
        this.errors = [];
        if (!this.form.name || !this.form.email || !this.form.phone || !this.form.message) {
          if (!this.form.name) this.errors.push('name')
          else this.errors.slice(this.errors.findIndex(e => e == 'name'), 1)
          if (!this.form.email) this.errors.push('email')
          else this.errors.slice(this.errors.findIndex(e => e == 'email'), 1)
          if (!this.form.phone) this.errors.push('phone')
          else this.errors.slice(this.errors.findIndex(e => e == 'phone'), 1)
          if (!this.form.message) this.errors.push('message')
          else this.errors.slice(this.errors.findIndex(e => e == 'message'), 1)
          if (this.form.file.type != 'application/pdf') this.errors.push('file')
          else this.errors.slice(this.errors.findIndex(e => e == 'file'), 1)
          this.$swal.fire({
            icon: 'error',
            title: 'Preenche os campos',
          })
          return
        }
        let send = {
          nome: this.form.name,
          email: this.form.email,
          telefone: this.form.phone,
          mensagem: this.form.message,
        }
        if (this.form.file.type == 'application/pdf') send = {
          ...send,
          anxName: this.form.fileName,
          anexo: this.form.fileAnexo,
        }
        this.loading = true;
        this.$store.dispatch('contato', send).then(() => {
          this.$swal.fire({
            icon: 'success',
            title: 'Obrigado por este e-mail!!',
          })
          this.form = {
            name: '',
            email: '',
            phone: '',
            message: '',
            file: '',
            fileName: '',
            fileAnexo: '',
          }
        }).catch((error) => this.$swal.fire({
          icon: 'error',
          title: `E-mail não enviado.\n${error}\nTente novamente mais tarde.`,
        })).finally(() => this.loading = false)
      },
      async loadFile(evt) {
        this.form.file = (evt.target.files[0]) ? evt.target.files[0] : '';
        this.form.fileName = (evt.target.files[0]) ? evt.target.files[0].name : '';
        let reader = new FileReader;
        await (reader.onload = (event) => {
          this.form.fileAnexo = event.currentTarget.result.slice(28);
        })
        if (evt.target.files[0]) await reader.readAsDataURL(evt.target.files[0]);
      }
    },
  }
</script>

<style scoped>
  h3 {
    /* color: #5d5d5d; */
    color: #e67e22;
    font-weight: 500;
    font-size: x-large;
  }

  .form-group .b-icon {
    position: absolute;
    top: 7px;
  }

  .form-contato {
    height: calc(100vh - 60px);
    border: 1px solid #e67e22;
  }

  .full{
    min-height: calc(100vh - 240px);
  }

  .form-control {
    border: 0;
    border-bottom: 1px solid #e67e22;
    border-radius: 0rem;
    padding: 0.2rem 0 0.2rem 1.5rem;
  }

  .form-control::placeholder {
    font-size: medium;
  }

  .form-control:focus {
    border-color: #e67e22;
  }

  textarea {
    max-height: 30vh;
  }

  #anexo label {
    color: #5d5d5d;
    cursor: pointer;
  }

  input[type='file'] {
    display: none;
  }

  .position {
    position: inherit !important;
  }

  [class*='btn-outline-'] {
    color: #fff;
    border: 2px solid #e67e22;
    border-radius: 1.5rem;
    background: #e67e22;
    transition: all 0.3s;
  }

  [class*='btn-outline-']:hover {
    color: #e67e22;
    background: #fff;
  }

  .btn-lg {
    list-style: none;
  }

  .icon {
    fill: #0d6efd;
    transition: all 0.3s;
    border-radius: 50%;
  }

  .icon:hover {
    fill: #fff;
    background: #0d6efd !important;
    transform: scale(2);
  }

  .icon:hover.bi-youtube {
    border-radius: 2px;
  }

  @media screen and (max-width: 992px) {
    .form-contato {
      height: auto;
    }
  }
</style>