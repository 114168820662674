<template>
  <div class="container px-3 px-lg-0">
    <router-link v-if="$route.name!='home'" :to="route||'/'">
      <b-icon icon="arrow-left-short" variant="dark" class="bg-white position-absolute border rounded-circle"
        font-scale="3" />
    </router-link>
    <div v-if="!noImg" class="row mx-0">
      <div class="col-lg-11 mx-auto">
        <div class="row m-0 align-items-center justify-content-between">
          <img id="logo-mbd" class="mt-4 mb-5 px-0" src="@/assets/mbd.png" alt="" draggable="false"/>
          <slot name="img" />
        </div>
        <slot name="start" />
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['noImg', 'route']
  }
</script>

<style scoped>
  .bi-arrow-left-short {
    top: 20px;
    left: 40px;
    z-index: 9;
    transition: all 0.3s;
  }

  .bi-arrow-left-short:hover {
    color: #00caff !important;
  }

  #logo-mbd {
    max-width: 186px;
    height: 100%;
  }

  .lead {
    font-size: 20px;
    line-height: 1.33;
    font-weight: 300;
    letter-spacing: .02em;
  }
</style>