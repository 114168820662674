<template>
  <section id="section-system">
    <start-page :noImg="true" :route="'/sistemas'" />
    <div v-if="setSystem != undefined" class="container px-0">
      <div id="header" :class="{'flex-column-reverse':($route.path == '/')}" class="row mx-0 align-items-center">
        <div class="col-11 col-lg-5 mt-3 mt-lg-5 mx-auto">
          <h2> {{ system[setSystem].title }} </h2>
          <p class="m-0"><strong v-html="system[setSystem].description"></strong></p>
          <router-link v-if="system[setSystem].link" :to="{ name: 'planos', params: { collapse: true }}" id="btnFechar"
            class="btn btn-lg btn-primary px-4 mt-3">Veja os recursos
          </router-link>
          <a v-else-if="system[setSystem].link.slice(0,5)=='https'" :href="system[setSystem].link" target="_blank"
            id="btnFechar" class="btn btn-lg btn-primary px-4 mt-3">Conheça os recursos
          </a>
          <p v-else class="pt-5 smallerText m-0">Para mais informações entre em contato, um agente irá retornar.</p>
        </div>
        <div :id="system[setSystem].imgStyle"
          class="col-12 col-lg-5 offset-lg-1 my-2 my-lg-auto px-0 position-relative">
          <img :src="require(`@/assets/sistemas/${system[setSystem].img}`)" alt="" draggable="false">
        </div>
      </div>
      <div id="modules" class="row mx-0 px-3">
        <h2 class="text-center my-4">Módulos</h2>
        <div v-for="m in system[setSystem].modules" :key="m.title"
          class="col-md-5 col-lg my-4 mx-md-auto mx-lg-3 p-3 card text-center">
          <img :src="require(`@/assets/sistemas/${m.icon}`)" class="p-2 mx-auto" alt="" draggable="false">
          <h3 class="mt-2 mb-4"><strong>{{ m.title }}</strong></h3>
          <p class="text-left m-0 h-100 d-flex align-items-center" v-html="m.description"></p>
        </div>
      </div>
      <div id="functions" class="row my-3 px-5 justify-content-center">
        <h2 class="text-center my-4">Funcionalidades</h2>
        <div v-for="f in system[setSystem].functions" :key="f"
          class="card col-5 col-md-3 col-lg-2 py-4 px-2 mx-auto mx-md-3 mx-lg-3 my-4 text-center">
          <h5 class="h-100 d-flex align-items-center align-self-center"><strong>{{ f }}</strong></h5>
        </div>
      </div>
      <!-- <div class="row mx-0">
        <div class="col-lg text-center">
          <button id="btnFechar" class="btn btn-lg btn-primary px-4 my-3">Fale conosco</button>
        </div>
      </div> -->
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
    </div>
  </section>
</template>

<script>
  import startPage from '../../components/start-page.vue'
  import copyright from '../../components/copyright.vue'
  export default {
    components: {
      startPage,
      copyright,
    },
    props: ['params'],
    data() {
      return {
        system: [{
          title: 'Software completo para Lanchonete',
          description: 'Nossa plataforma facilita a automatização dos seus pedidos físicos e virtuais.<br>Tenha o controle do seu negócio.',
          img: 'IMG Lanchonete.png',
          imgStyle: 'lanchonete',
          link: '/planos',
          modules: [{
            icon: 'mesas.png',
            title: 'Mesas',
            description: 'Realize pedidos, fechamentos de contas, gerenciador de impressão para pedidos na coxinha ou bar.<br>App para garçons.',
          }, {
            icon: 'comanda.png',
            title: 'Comanda',
            description: 'Tenha o controle individual dos pedidos, possibilitado integração com catracas de fichas na entrada e saída.',
          }, {
            icon: 'take-away.png',
            title: 'Balcão',
            description: 'Tenha a possibilidade de realizar pedidos rápidos no balcão de atendimento.',
          }, {
            icon: 'delivery.png',
            title: 'Delivery',
            description: 'Controle de pedidos e motoboy integração com robô WhatsApp E pagina para pedidos online.<br>Sem taxas.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Aplicativos',
            'Integração com Balança', 'Impressão Remota', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Software completo para Bares e Restaurantes',
          description: 'Nossa plataforma facilita a automatização dos seus pedidos físicos e virtuais.<br>Tenha o controle do seu negócio.',
          img: 'IMG BARES.jpg',
          imgStyle: 'bares',
          link: '/planos',
          modules: [{
            icon: 'mesas.png',
            title: 'Mesas',
            description: 'Realize pedidos, fechamentos de contas, gerenciador de impressão para pedidos na coxinha ou bar.<br>App para garçons.',
          }, {
            icon: 'comanda.png',
            title: 'Comanda',
            description: 'Tenha o controle individual dos pedidos, possibilitado integração com catracas de fichas na entrada e saída.',
          }, {
            icon: 'take-away.png',
            title: 'Balcão',
            description: 'Tenha a possibilidade de realizar pedidos rápidos no balcão de atendimento.',
          }, {
            icon: 'delivery.png',
            title: 'Delivery',
            description: 'Controle de pedidos e motoboy integração com robô WhatsApp E pagina para pedidos online.<br>Sem taxas.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Aplicativos',
            'Integração com Balança', 'Impressão Remota', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Software completo para Padarias e Confeitarias',
          description: 'Nossa plataforma facilita a automatização dos seus pedidos físicos e virtuais.<br>Tenha o controle do seu negócio.',
          img: 'IMG Padaria.jpg',
          imgStyle: 'padarias',
          link: '/planos',
          modules: [{
            icon: 'mesas.png',
            title: 'Mesas',
            description: 'Realize pedidos, fechamentos de contas, gerenciador de impressão para pedidos na coxinha ou bar.<br>App para garçons.',
          }, {
            icon: 'comanda.png',
            title: 'Comanda',
            description: 'Tenha o controle individual dos pedidos, possibilitado integração com catracas de fichas na entrada e saída.',
          }, {
            icon: 'take-away.png',
            title: 'Balcão',
            description: 'Tenha a possibilidade de realizar pedidos rápidos no balcão de atendimento.',
          }, {
            icon: 'delivery.png',
            title: 'Delivery',
            description: 'Controle de pedidos e motoboy integração com robô WhatsApp E pagina para pedidos online.<br>Sem taxas.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Aplicativos',
            'Integração com Balança', 'Impressão Remota', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Software completo para Pizzaria',
          description: 'Nossa plataforma facilita a automatização dos seus pedidos físicos e virtuais.<br>Tenha o controle do seu negócio.',
          img: 'IMG pizza.jpg',
          imgStyle: 'pizzaria',
          link: '/planos',
          modules: [{
            icon: 'mesas.png',
            title: 'Mesas',
            description: 'Realize pedidos, fechamentos de contas, gerenciador de impressão para pedidos na coxinha ou bar.<br>App para garçons.',
          }, {
            icon: 'comanda.png',
            title: 'Comanda',
            description: 'Tenha o controle individual dos pedidos, possibilitado integração com catracas de fichas na entrada e saída.',
          }, {
            icon: 'take-away.png',
            title: 'Balcão',
            description: 'Tenha a possibilidade de realizar pedidos rápidos no balcão de atendimento.',
          }, {
            icon: 'delivery.png',
            title: 'Delivery',
            description: 'Controle de pedidos e motoboy integração com robô WhatsApp E pagina para pedidos online.<br>Sem taxas.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Aplicativos',
            'Integração com Balança', 'Impressão Remota', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Loja de Materiais de Construção',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'contrucao.jpg',
          imgStyle: 'lanchonete',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Loja de Vestuário',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'IMG Vestuario.jpg',
          imgStyle: 'vestuario',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Loja de Joias',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'joia.jpg',
          imgStyle: 'bares',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Pagamentos Online',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'pagamento onlne.jpg',
          imgStyle: 'padarias',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Fiscal',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'fiscal.jpg',
          imgStyle: 'vestuario',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Varejo em geral',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'varejo.jpg',
          imgStyle: 'bares',
          link: 'https://office.pdvx.com.br/nossos-planos/',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Software completo para Mercados e Conveniências',
          description: 'Nossa plataforma facilita a automatização dos seus pedidos físicos e virtuais.<br>Tenha o controle do seu negócio.',
          img: 'supermercado1.jpg',
          imgStyle: 'lanchonete',
          link: '/planos',
          modules: [{
            icon: 'mesas.png',
            title: 'Mesas',
            description: 'Realize pedidos, fechamentos de contas, gerenciador de impressão para pedidos na coxinha ou bar.<br>App para garçons.',
          }, {
            icon: 'comanda.png',
            title: 'Comanda',
            description: 'Tenha o controle individual dos pedidos, possibilitado integração com catracas de fichas na entrada e saída.',
          }, {
            icon: 'take-away.png',
            title: 'Balcão',
            description: 'Tenha a possibilidade de realizar pedidos rápidos no balcão de atendimento.',
          }, {
            icon: 'delivery.png',
            title: 'Delivery',
            description: 'Controle de pedidos e motoboy integração com robô WhatsApp E pagina para pedidos online.<br>Sem taxas.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Aplicativos',
            'Integração com Balança', 'Impressão Remota', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, {
          title: 'Plataforma online para Medicina do Trabalho',
          description: 'Nossa plataforma totalmente online para você gerenciar seu negócio de onde e quando quiser.',
          img: 'medicina.jpg',
          imgStyle: 'padarias',
          link: '',
          modules: [{
            icon: 'desk.png',
            title: 'PdvX Desk',
            description: 'Pdv online com vendas em contingência, preparados para as legislações fiscais integrada com meios de pagamentos e PIX.',
          }, {
            icon: 'mobile.png',
            title: 'PdvX Mobile',
            description: 'Tenha o pdv a palma de sua mão rodando em smartphones e smartPOS com integração com meio de pagamentos.',
          }, {
            icon: 'office.png',
            title: 'Office Gestão',
            description: 'Uma plataforma completa para ajuda na gestão do seu negócio.<br>Preparado para as legislações brasileiras na emissão fiscal.',
          }, {
            icon: 'fiscal.png',
            title: 'Fiscal',
            description: 'Módulos de NF-E, SAT, NFC-e, MDF-e e NFS-e, muito fácil e seguro. <br>Tenha todos seus arquivos XMLs armazenados em nosso cofre.',
          }],
          functions: ['Controle Financeiro', 'Controle Mensalista', 'NF-e, Sat e NFC-e', 'Mobile',
            'Grade de Produtos', 'Orçamentos', 'Controle de Estoque', 'Pagamento Online'
          ]
        }, ],
      }
    },
    beforeMount() {
      if ((this.$route.path != '/') && (this.$route.params.system == undefined)) this.$router.push('sistemas')
      // this.setSystem = (this.params) ? this.params : this.$route.params.system;
    },
    computed: {
      setSystem() {
        return (this.params >= 0) ? this.params : this.$route.params.system
      }
    }
  }
</script>

<style scoped>
  #system * {
    font-family: 'Poppins', sans-serif !important;
  }

  h2 {
    font-weight: 700;
  }

  .btn {
    border-radius: 50px;
  }

  .smallerText{
    font-size: smaller;
  }

  #lanchonete img {
    height: 400px;
    width: 110%;
    margin-top: -50px;
    margin-bottom: 50px;
    object-position: top left;
    object-fit: cover;
    border-radius: 100px 0 0 100px;
  }

  #lanchonete::before {
    content: "";
    position: absolute;
    top: -80%;
    right: 0;
    bottom: 0;
    left: -15%;
    background: #e67e22;
    z-index: -1;
    border-radius: 95% 0 0 20%;
    transform: rotate(-3deg);
  }

  #bares img {
    height: 400px;
    width: 100%;
    object-position: center;
    object-fit: cover;
    border-radius: 50% 0 0 50%;
  }

  #bares::before {
    content: "";
    position: absolute;
    top: -50%;
    right: 0;
    bottom: 10%;
    left: -30%;
    background: #e67e22;
    z-index: -1;
    border-radius: 50% 0 0 50%;
  }

  #padarias img {
    height: 300px;
    width: 150%;
    margin-top: 50px;
    margin-bottom: 50px;
    object-position: center;
    object-fit: cover;
  }

  #padarias::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30%;
    left: -15%;
    background: #e67e22;
    z-index: -1;
  }

  #pizzaria img {
    height: 400px;
    width: 100%;
    object-position: top left;
    object-fit: cover;
    border-radius: 100px 200px 200px 0;
    transform: rotate(180deg);
  }

  #pizzaria::before {
    content: "";
    position: absolute;
    top: -50%;
    right: 10%;
    bottom: 10%;
    left: -30%;
    background: #e67e22;
    z-index: -1;
    border-radius: 50% 0 0 50%;
  }

  #vestuario img {
    height: 400px;
    width: 100%;
    object-position: top center;
    object-fit: cover;
    border-radius: 300px 0 0 200px;
  }

  .card img {
    width: 80px;
    height: 80px;
  }

  .card h3 {
    color: #e67e22;
  }

  .card h5 {
    color: #e67e22;
  }

  .card p {
    font-size: medium;
  }

  #functions .card {
    transition: all 0.3s;
  }

  #functions .card:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 992px) {
    .btn-lg {
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
    }

    #header img {
      width: 100% !important;
      height: auto !important;
      margin: auto !important;
    }

    #functions h5{
      font-size: smaller;
    }
  }
</style>