<template>
  <section id="section-works">
    <div class="container px-0">
      <start-page :noImg="true" />
      <div class="row align-items-center m-0 bg-dark">
        <div class="col-12 col-lg-6 p-0">
          <img class="w-100" :src="require(`@/assets/sistemas/capa1.jpg`)" draggable="false">
        </div>
        <div class="col-10 mx-auto col-lg-6 my-5 px-lg-5 text-white">
          <h3>Precisando vender mais?</h3>
          <p class="m-0">Nós podemos ajudar o seu negócio a alcançar suas metas</p>
          <div class="text-right mt-5 mx-3">
            <button @click="about()" id="btnFechar" class="btn btn-outline-orange">Saiba mais</button>
          </div>
        </div>
      </div>
      <div class="row my-5 mx-0">
        <div class="col-11 col-12 my-3 mx-auto text-center">
          <h2 class="text-orange my-4">Um software desenhado especialmente para seu negócio</h2>
          <p class="m-0"><b>Somos uma software house especializada em sistemas para varejos ou food service, em nuvem ou
              local.</b></p>
          <p class="m-0"><b>São dezenas de ferramentas desenvolvidas para auxiliar na sua gestão.</b></p>
        </div>
      </div>
      <div class="row m-0">
        <div @mousedown="mouse=true" @mouseup="mouse=false" class="col-12 p-0 my-5">
          <vue-slick-carousel v-bind="settings" :class="{'cursor-grab': !mouse,'cursor-grabbing': mouse}"
            class="bg py-2 px-4">
            <template #prevArrow="">
              <div class="custom-arrow"></div>
            </template>
            <template #nextArrow="">
              <div class="custom-arrow"> </div>
            </template>
            <div id="segmentos" v-for="(s,i) in segmentos" :key="'segments-'+i"
              class="p-3 w-50 mx-auto mx-lg-4 bg-white rounded text-center">
              <div class="row align-items-center">
                <h5 class="p-3 m-0"> {{ s.title }} </h5>
              </div>
              <div class="rounded-circle border mx-auto my-3">
                <img :src="require(`@/assets/sistemas/${s.icon}`)" class="mx-auto p-3" alt="" draggable="false">
              </div>
              <button @click="plus(i)" class="my-3 btn" id="btnFechar">Saiba mais</button>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
      <div v-if="$route.path == '/'" class="row">
        <b-collapse id="collapse-system" v-model="visible">
          <div class="col-11 px-0 text-right">
            <b-button @click="visible=false" id="btnFechar" size="sm" variant="danger" class="p-0 rounded">
              <b-icon icon="x" font-scale="2" aria-hidden="true"></b-icon>
            </b-button>
          </div>
          <system v-bind="{params}" />
        </b-collapse>
      </div>
      <div v-if="$route.name!='home'" class="row">
        <copyright />
      </div>
    </div>
  </section>
</template>

<script>
  import startPage from "../components/start-page.vue";
  import system from "./components/system.vue";
  import copyright from "../components/copyright.vue";
  import VueSlickCarousel from "vue-slick-carousel";
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
  export default {
    components: {
      startPage,
      system,
      copyright,
      VueSlickCarousel,
    },
    data() {
      return {
        settings: {
          arrows: true,
          slidesToShow: 4,
          initialSlide: 0,
          infinite: true,
          slidesToScroll: 1,
          swipeToSlide: true,
          speed: 500,
          centerPadding: "20px",
          // autoplay: true,
          autoplaySpeed: 5000,
          pauseOnDotsHover: true,
          pauseOnFocus: true,
          pauseOnHover: true,
          // variableWidth: true,
          responsive: [{
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        },
        segmentos: [{
            id: "fast-food",
            title: "Fast-food",
            icon: "fastfood.png",
          },
          {
            id: "bares-restaurantes",
            title: "Bares e Restaurantes",
            icon: "restaurante.png",
          },
          {
            id: "padarias",
            title: "Padarias",
            icon: "padaria.png",
          },
          {
            id: "pizzaria",
            title: "Pizzaria",
            icon: "pizza.png",
          },
          {
            id: "material-construção",
            title: "Material Construção",
            icon: "pedreiro.png",
          },
          {
            id: "vestuario",
            title: "Vestuário",
            icon: "sapato.png",
          },
          {
            id: "joias",
            title: "Joias",
            icon: "anel.png",
          },
          {
            id: "pagamento-online",
            title: "Pagamento Online",
            icon: "pagamento-online.png",
          },
          {
            id: "fiscal",
            title: "Fiscal",
            icon: "fiscal.png",
          },
          {
            id: "varejo",
            title: "Varejo em geral",
            icon: "market.png",
          },
          {
            id: "mercados-conveniencia",
            title: "Mercados e Conveniência",
            icon: "supermercado.png",
          },
          // {
          //   id: "medicina-trabalho",
          //   title: "Medicina do Trabalho",
          //   icon: "fiscal.png",
          // },
        ],
        visible: false,
        params: undefined,
        mouse: false,
      };
    },
    methods: {
      about() {
        if (this.$route.path != '/') this.$router.push('/planos')
        else setTimeout(() => window.scroll(0, document.querySelector('#section-store').offsetTop), 250);
      },
      plus(i) {
        if (this.$route.path != '/') this.$router.push({
          name: 'sistemas-info',
          params: {
            system: i
          }
        })
        else {
          this.params = i
          this.visible = true;
          setTimeout(() => window.scroll(0, document.querySelector('#collapse-system').offsetTop), 250);
        }
      },
    }
  };
</script>

<style scoped>
  #section-works {
    background: #f8f9fa !important;
  }

  #section-works * {
    font-family: "Poppins", sans-serif !important;
  }

  img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -ms-user-drag: none;
    user-drag: none;
  }

  .btn-outline-orange {
    color: #fff;
    border: 1px solid #fff;
    background: #e67e22;
  }

  .btn-outline-orange:hover {
    color: #e67e22;
    border: 1px solid #e67e22;
    background: #fff;
  }

  .cursor-grab {
    cursor: grab;
  }

  .cursor-grabbing {
    cursor: grabbing;
  }

  #segmentos .btn {
    color: #e67e22;
    border: 1px solid #e67e22;
    background: #fff;
  }

  #segmentos .btn:hover {
    color: #fff;
    border: 1px solid #fff;
    background: #e67e22;
  }

  h2 {
    font-size: xx-large;
    color: #e67e22;
  }

  .custom-arrow {
    z-index: 999;
  }

  .custom-arrow::before {
    color: #333;
    font-size: 2rem;
  }

  .bg {
    background: lightgray;
  }

  #segmentos {
    width: 200px !important;
    border-radius: 1.75rem !important;
  }

  #segmentos .row {
    min-height: 78px;
  }

  .rounded-circle {
    /* box-shadow: 0 0 10px #333; */
    width: 100px;
    height: 100px;
  }

  .rounded-circle img {
    width: 100px;
  }

  @media screen and (max-width: 992px) {
    h2 {
      font-size: 28px;
    }

    p {
      font-size: medium;
      text-align: left !important;
    }
  }
</style>

<style>
  .slick-prev {
    left: 5px;
  }

  .slick-next {
    right: 15px;
  }

  .slick-slide+div {
    text-align: center;
  }
</style>