import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'
import axios from 'axios'
import routes from './routes';
import store from './store';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/common.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueTheMask from 'vue-the-mask'
import AnimatedNumber from "animated-number-vue";
import { VueEditor } from "vue2-editor";

Vue.component('AnimatedNumber', AnimatedNumber);
Vue.component('VueEditor', VueEditor);

const router = new VueRouter({
  routes: routes,
  mode: 'history',
});

const optSwal = {
  toast: true,
  position: 'top-end',
  showCloseButton: true,
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  width: '25rem',
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Vue.swal.stopTimer)
    toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
  }
};
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.prototype.axios = axios;
Vue.prototype.swal = (icon, title, err) =>
  Vue.swal.fire({ icon: icon ? 'success' : 'error', title: (title || `Falha no processo. Tente novamente mais tarde.${err ? `\n${err}` : ``}`) });

Vue.use(VueRouter);
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueSweetalert2, optSwal)
Vue.use(VueTheMask)


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')

console.log(process.env.VUE_APP_BASE_URL);